import React from 'react';
import StatusSection from "../status/StatusSection";
import LogsSection from "../logs/LogsSection";

const dashboard = () => {
    return (
        <>
            <StatusSection/>
            <LogsSection/>
        </>
    )
}
export default dashboard;
